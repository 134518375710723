import { generatePath, useNavigate, useParams } from "react-router-dom";

import { useLanguage } from "src/contexts/languages";
import {
    URL_CONFIGURATION_PLATFORM,
    URL_SUBPROJECT_WORKSPACE_VIEW,
    URL_TEAMOTY_PROJECT,
} from "src/router";

type RedirectToNotAuthorizedConfig =
    | typeof URL_CONFIGURATION_PLATFORM
    | typeof URL_TEAMOTY_PROJECT;

export const useRedirection = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const urlParams = useParams();

    const redirectToCgu = (replace?: boolean) => {
        navigate(`/${language}/cgu`, {
            replace: replace,
        });
    };

    const redirectToProjects = (replace?: boolean) => {
        navigate(generatePath(`/${language}/projects`, { ...urlParams }), {
            replace: replace,
        });
    };

    const redirectToAddCompany = (replace?: boolean) => {
        navigate(generatePath(`/${language}/add-company`, { ...urlParams }), {
            replace: replace,
        });
    };

    const redirectToMetadataForm = (replace?: boolean) => {
        navigate(generatePath(`/${language}/fill-metadata`, { ...urlParams }), {
            replace: replace,
        });
    };

    const redirectToLogin = (replace?: boolean) => {
        navigate(`/${language}/login`, {
            replace: replace,
        });
    };

    const redirectToPlanningHub = (replace?: boolean) => {
        const newUrl = generatePath(
            `${URL_SUBPROJECT_WORKSPACE_VIEW}/planning-hub`,
            { ...urlParams },
        );
        navigate(newUrl, {
            replace: replace,
        });
    };

    const redirectToMatrixSequenceArea = (
        sequenceId: number,
        replace?: boolean,
    ) => {
        const newUrl = generatePath(
            `${URL_SUBPROJECT_WORKSPACE_VIEW}/matrix-sequence-area/${sequenceId}`,
            { ...urlParams },
        );
        navigate(newUrl, {
            replace: replace,
        });
    };

    const redirectToNotAuthorized = (
        config: RedirectToNotAuthorizedConfig = URL_CONFIGURATION_PLATFORM,
        suffix: string = "",
        replace?: boolean,
    ) => {
        const url = `/${config}${suffix}/not-authorized`;

        navigate(generatePath(url, { ...urlParams }), {
            replace: replace,
        });
    };

    return {
        redirectToProjects,
        redirectToCgu,
        redirectToAddCompany,
        redirectToMetadataForm,
        redirectToLogin,
        redirectToPlanningHub,
        redirectToMatrixSequenceArea,
        redirectToNotAuthorized,
    };
};
