import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

export const subprojectSettingsCategoriesList: Type_sidebar_configuration = {
    nameLabel: "SubProject",
    baseUrl: "/projects/:projectSlug/settings/subproject/:subProjectId",
    blocks: [
        {
            categories: [
                {
                    nameLabel: "Areas",
                    urlName: "areas",
                    permissionKeys: ["Project.SubProjectSettings.Areas"],
                    variant: "light",
                    icon: "layer-group",
                    contentType: "table",
                },
                {
                    nameLabel: "Trades",
                    urlName: "trades",
                    permissionKeys: ["Project.SubProjectSettings.Trades"],
                    variant: "light",
                    icon: "tag",
                    contentType: "table",
                },
                {
                    nameLabel: "Drawings",
                    urlName: "drawings",
                    permissionKeys: ["Project.SubProjectSettings.Drawings"],
                    variant: "light",
                    icon: "vector-square",
                    contentType: "table",
                },
                {
                    nameLabel: "Resources",
                    urlName: "resources",
                    permissionKeys: [
                        "Project.SubProjectSettings.ResourceTypes",
                        "Project.SubProjectSettings.Resources",
                    ],
                    variant: "light",
                    icon: "toolbox",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey:
                                "Project.SubProjectSettings.ResourceTypes",
                            nameLabel: "Resources",
                            urlName: "resources",
                        },
                        {
                            permissionKey:
                                "Project.SubProjectSettings.Resources",
                            nameLabel: "ResourceTypes",
                            urlName: "resource-types",
                        },
                    ],
                },
                {
                    nameLabel: "DaysOff",
                    urlName: "days-off",
                    permissionKeys: ["Project.SubProjectSettings.DayOffs"],
                    variant: "light",
                    icon: "calendar-xmark",
                    contentType: "table",
                },
                // {
                //     nameLabel: "Reports",
                //     urlName: "reports",
                //     // TODO change permission for reports
                //     permissionKeys: [],
                //     variant: "light",
                //     icon: "file-word",
                //     contentType: "table",
                // },
                {
                    nameLabel: "Users",
                    urlName: "user",
                    permissionKeys: [
                        "Project.SubProjectSettings.SubProjectUsers",
                    ],
                    variant: "light",
                    icon: "user",
                    contentType: "table",
                },
                {
                    nameLabel: "Companies",
                    urlName: "companies",
                    permissionKeys: [
                        "Project.SubProjectSettings.SubProjectCompanies",
                    ],
                    variant: "light",
                    icon: "building",
                    contentType: "table",
                },
                {
                    nameLabel: "KeyDates",
                    urlName: "key-dates",
                    permissionKeys: ["Project.SubProjectSettings.KeyDates"],
                    variant: "light",
                    icon: "calendar-days",
                    contentType: "table",
                },
                {
                    nameLabel: "DefaultSettings",
                    urlName: "default-settings",
                    permissionKeys: [
                        "Project.SubProjectSettings.DefaultSettings",
                    ],
                    variant: "light",
                    icon: "gear",
                    contentType: "section",
                },
            ],
        },
    ],
};
