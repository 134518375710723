import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

export const configurationCategoriesList: Type_sidebar_configuration = {
    nameLabel: "PlatformConfiguration",
    baseUrl: "/platform-configuration",
    blocks: [
        {
            title: "LicencesAndRoles",
            categories: [
                {
                    nameLabel: "RoleTypes",
                    urlName: "roles",
                    permissionKeys: ["Global.PlatformConfiguration.Roles"],
                    variant: "light",
                    icon: "route-interstate",
                    contentType: "table",
                },
                {
                    nameLabel: "LicenseTypes",
                    urlName: "license-types",
                    permissionKeys: [
                        "Global.PlatformConfiguration.LicenseTypes",
                    ],
                    variant: "light",
                    icon: "badge",
                    contentType: "table",
                },
            ],
        },
        {
            title: "ProjectsSettings",
            categories: [
                {
                    nameLabel: "ProjectTypes",
                    urlName: "projects",
                    permissionKeys: [
                        "Global.PlatformConfiguration.AreaTypes",
                        "Global.PlatformConfiguration.ProjectTypes",
                    ],
                    variant: "light",
                    icon: "diagram-project",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.ProjectTypes",
                            nameLabel: "ProjectTypes",
                            urlName: "project-types",
                        },
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.AreaTypes",
                            nameLabel: "AreaTypes",
                            urlName: "area-types",
                        },
                    ],
                },
                {
                    nameLabel: "Causes",
                    urlName: "causes",
                    permissionKeys: [
                        "Global.PlatformConfiguration.CausesOfDelay",
                        "Global.PlatformConfiguration.PunchlistItemCauses",
                    ],
                    variant: "light",
                    icon: "list-ol",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.CausesOfDelay",
                            nameLabel: "CausesOfDelay",
                            urlName: "causes-of-delay",
                        },
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.PunchlistItemCauses",
                            nameLabel: "PunchlistItemCauses",
                            urlName: "punchlist-item-causes",
                        },
                    ],
                },
                {
                    nameLabel: "Metadata",
                    urlName: "metadata",
                    permissionKeys: [
                        "Global.PlatformConfiguration.MetadataProjects",
                        "Global.PlatformConfiguration.MetadataUsers",
                    ],
                    variant: "light",
                    icon: "tag",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.MetadataProjects",
                            nameLabel: "MetadataProjects",
                            urlName: "projects",
                        },
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.MetadataUsers",
                            nameLabel: "MetadataUsers",
                            urlName: "users",
                        },
                    ],
                },
                {
                    nameLabel: "ResourceModels",
                    urlName: "resources",
                    permissionKeys: [
                        "Global.PlatformConfiguration.ResourceModels",
                        "Global.PlatformConfiguration.ResourceFamilies",
                    ],
                    variant: "light",
                    icon: "toolbox",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.ResourceModels",
                            nameLabel: "Model",
                            urlName: "resource-models",
                        },
                        {
                            permissionKey:
                                "Global.PlatformConfiguration.ResourceFamilies",
                            nameLabel: "ResourceFamilies",
                            urlName: "resource-families",
                        },
                    ],
                },
            ],
        },
        {
            title: "GlobalSettings",
            categories: [
                {
                    nameLabel: "Units",
                    urlName: "units",
                    permissionKeys: [
                        "Global.PlatformConfiguration.Units",
                        "Global.PlatformConfiguration.UnitTypes",
                    ],
                    variant: "light",
                    icon: "ruler",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey: "Global.PlatformConfiguration.Units",
                            nameLabel: "Units",
                            urlName: "units",
                        },

                        {
                            permissionKey:
                                "Global.PlatformConfiguration.UnitTypes",
                            nameLabel: "UnitTypes",
                            urlName: "unit-types",
                        },
                    ],
                },
                {
                    nameLabel: "TimeModels",
                    urlName: "time-models",
                    permissionKeys: ["Global.PlatformConfiguration.TimeModels"],
                    variant: "light",
                    icon: "clock",
                    contentType: "table",
                },
                {
                    nameLabel: "PriorityOfConstraints",
                    urlName: "priority-of-constraints",
                    permissionKeys: [
                        "Global.PlatformConfiguration.PriorityOfConstraints",
                    ],
                    variant: "light",
                    icon: "arrow-up-right-dots",
                    contentType: "table",
                },
                {
                    nameLabel: "PlatformSettings",
                    urlName: "platform-settings",
                    permissionKeys: [
                        "Global.PlatformConfiguration.PlatformSettings",
                    ],
                    variant: "light",
                    icon: "gear",
                    contentType: "section",
                },
            ],
        },
    ],
};
